<template>
    <button class="pt-2 pb-1 px-4 focus:outline-none">
        <div><icon :icon="icon"></icon></div>
        <span class="text-sm">{{ label }}</span>
    </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Icon from '@/components/Icon.vue';

export default defineComponent({
    components: {
        Icon,
    },

    props: {
        icon: { type: String },
        label: { type: String },
    },
});
</script>

<style scoped>
button {
    min-width: 5rem;
}
button:hover {
    background-color: rgba(255, 255, 255, 0.1);
}
</style>
