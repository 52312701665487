import { Card } from '@/core/models';

type Move = {
    cards: Card[];
};

export class MoveHistory {
    private moves: Move[] = [];

    private currentMove: Move | null = null;

    startMove() {
        this.currentMove = { cards: [] };
    }

    addState(card: Card) {
        if (!this.currentMove) {
            throw new Error('no open move');
        }
        this.currentMove.cards.push({
            ...card,
            isDragging: false,
            isMoving: false,
        });
    }

    endMove() {
        if (!this.currentMove) {
            throw new Error('no open move');
        }
        this.moves.push(this.currentMove);
        this.currentMove = null;
    }

    reset() {
        this.moves = [];
    }

    pop() {
        return this.moves.pop();
    }
}

export const moveHistory = new MoveHistory();
