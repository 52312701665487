
import { ref, defineComponent, watch } from 'vue';
import { coreBus } from '@/core/core-bus';
import { subscribeTo } from '@/core/rxjs-helpers';

export default defineComponent({
    props: {
        modelValue: { type: Boolean },
    },

    setup(props, ctx) {
        const show = ref(false);

        const close = () => {
            show.value = false;
            ctx.emit('update:modelValue', false);
        };

        subscribeTo(coreBus.overlayClickedEvent$, () => {
            console.log('-----overlay clicked');
            close();
        });

        watch(
            () => props.modelValue,
            (val) => {
                show.value = val as boolean;
                coreBus.overlayToggleCmd$.next(val);
            }
        );

        return {
            show,
            close,
        };
    },
});
