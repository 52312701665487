import { Query } from '@datorama/akita';
import { GameState } from '@/state/game/game.store';
import { gameStore } from '@/state/game/game.store';

export class GameQuery extends Query<GameState> {
    gameStatus$ = this.select((state) => state.gameStatus);

    gameSize$ = this.select((state) => state.gameSize);

    gameId$ = this.select((s) => s.gameId);

    gameLeaderboard$ = this.select((s) => s.gameLeaderboard);

    metrics$ = this.select((s) => s.gameMetrics);

    canAutoFinish$ = this.select((s) => s.canAutoFinish);

    foundationIndexHighlight$ = this.select((s) => s.foundationIndexHighlight);

    stockRecycleCounter$ = this.select((s) => s.stockRecycleCounter);

    hints$ = this.select((s) => s.hints);

    tableauIndexHighlight$ = this.select((s) => s.tableauIndexHighlight);

    foundationLeadingNumber$ = this.select((s) => s.foundationLeadingNumber);
}

export const gameQuery = new GameQuery(gameStore);
