import { Howl } from 'howler';
import { coreLocalStorage } from '@/core/core-local-storage';

class CardSound {
    private shuffle;
    private move;
    private undo;
    private error;
    private start;
    private victory;
    private scores: Howl[] = [];
    private lastPlayedScoreId = 0;
    private lastPlayedScoreTime = 0;
    private maxScoreId = 11;
    private maxScoreTimeToReset = 5000;
    private isAutoFinish = false;
    private autoFinishInterval = 0;

    constructor() {
        this.shuffle = new Howl({
            src: ['/sound/card/shuffle.mp3'],
        });
        this.move = new Howl({
            src: ['/sound/card/ping.mp3'],
        });
        this.undo = new Howl({
            src: ['/sound/card/undo.mp3'],
        });
        this.error = new Howl({
            src: ['/sound/card/error.mp3'],
        });
        this.start = new Howl({
            src: ['/sound/card/start.mp3'],
        });
        this.victory = new Howl({
            src: ['/sound/card/victory.mp3'],
        });
        this.scores[1] = new Howl({
            src: ['/sound/card/score1.mp3'],
        });
        this.scores[2] = new Howl({
            src: ['/sound/card/score2.mp3'],
        });
        this.scores[3] = new Howl({
            src: ['/sound/card/score3.mp3'],
        });
        this.scores[4] = new Howl({
            src: ['/sound/card/score4.mp3'],
        });
        this.scores[5] = new Howl({
            src: ['/sound/card/score5.mp3'],
        });
        this.scores[6] = new Howl({
            src: ['/sound/card/score6.mp3'],
        });
        this.scores[7] = new Howl({
            src: ['/sound/card/score7.mp3'],
        });
        this.scores[8] = new Howl({
            src: ['/sound/card/score8.mp3'],
        });
        this.scores[9] = new Howl({
            src: ['/sound/card/score9.mp3'],
        });
        this.scores[10] = new Howl({
            src: ['/sound/card/score10.mp3'],
        });
        this.scores[11] = new Howl({
            src: ['/sound/card/score11.mp3'],
        });
    }

    playShuffle() {
        if (coreLocalStorage.loadPlayerSettings().mute) {
            return;
        }
        this.shuffle.play();
    }

    playMove() {
        if (coreLocalStorage.loadPlayerSettings().mute) {
            return;
        }
        this.move.play();
    }

    playUndo() {
        if (coreLocalStorage.loadPlayerSettings().mute) {
            return;
        }
        this.undo.play();
    }

    playError() {
        if (coreLocalStorage.loadPlayerSettings().mute) {
            return;
        }
        this.error.play();
    }

    playStart() {
        if (coreLocalStorage.loadPlayerSettings().mute) {
            return;
        }
        this.start.play();
    }

    playVictory() {
        if (coreLocalStorage.loadPlayerSettings().mute) {
            return;
        }
        this.victory.play();
    }

    playScore() {
        if (coreLocalStorage.loadPlayerSettings().mute) {
            return;
        }
        if (this.isAutoFinish) {
            return;
        }
        const id = this.getNextScoreId();
        this.scores[id].play();
        this.lastPlayedScoreId = id;
        this.lastPlayedScoreTime = new Date().getTime();
    }

    startAutoFinish() {
        if (coreLocalStorage.loadPlayerSettings().mute) {
            return;
        }
        this.isAutoFinish = true;
        let id = 1;
        this.scores[id].play();
        this.autoFinishInterval = setInterval(() => {
            if (id < this.maxScoreId) {
                this.scores[id].play();
                console.log('---id', id);
            }
            id += 1;
        }, 400);
    }

    endAutoFinish() {
        this.isAutoFinish = false;
        clearInterval(this.autoFinishInterval);
    }

    reset() {
        this.lastPlayedScoreTime = 0;
        this.lastPlayedScoreId = 0;
    }

    private getNextScoreId() {
        if (this.lastPlayedScoreId == 0) {
            return 1;
        }

        const diff = new Date().getTime() - this.lastPlayedScoreTime;
        if (diff > this.maxScoreTimeToReset) {
            return 1;
        }

        if (this.lastPlayedScoreId == this.maxScoreId) {
            return 1;
        }

        return this.lastPlayedScoreId + 1;
    }
}

export const cardSound = new CardSound();
