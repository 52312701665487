import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Card } from '@/core/models';

export interface CardsState extends EntityState<Card> {
    tableauCounts: { [id: number]: number };
}

const initialState = {
    tableauCounts: {},
};

@StoreConfig({ name: 'global-cards' })
export class CardsStore extends EntityStore<CardsState> {
    constructor() {
        super(initialState);
    }
}

export const cardsStore = new CardsStore();
