
import { ref, defineComponent } from 'vue';
import { coreLocalStorage } from '@/core/core-local-storage';
import { apiClient } from '@/core/api-client';
import SButton from '@/components/SButton.vue';

export default defineComponent({
    components: { SButton },

    setup(_, ctx) {
        const name = ref('');
        const showChooseNamePanel = ref(false);

        const saveName = () => {
            if (name.value != '') {
                coreLocalStorage.updatePlayerSettings({
                    name: name.value,
                });
                apiClient.updatePlayer({
                    name: name.value,
                });
                ctx.emit('on-name-saved');
            }
        };

        return {
            showChooseNamePanel,
            saveName,
            name,
        };
    },
});

/*
@Component({
    components: { SButton }
})
export default class MissingPlayerName extends Vue {
    name = '';

    showChooseNamePanel = false;

    saveName() {
        if (this.name != '') {
            coreLocalStorage.updatePlayerSettings({
                name: this.name
            });
            apiClient.updatePlayer({
                name: this.name
            });
            this.$emit('on-name-saved');
        }
    }
}
 */
