import { Card, CardOwner } from '@/core/models';
import { cardsStore } from '@/state/cards/cards.store';
import { cardsQuery } from '@/state/cards/cards.query';

export class CardsService {
    init(cards: Card[]) {
        cardsStore.set(cards);
        cardsStore.update({
            tableauCounts: {},
        });
    }

    resetAllCards(owner: CardOwner = CardOwner.none) {
        const updates = cardsQuery.getAll().map((c) => ({
            ...c,
            owner,
            dragEnabled: false,
            isFaceUp: false,
            isShadow: false,
            isHighlight: false,
        }));
        cardsStore.upsertMany(updates);
    }

    update(id: string | null, data: Partial<Card>) {
        cardsStore.update(id, data);
    }

    updateCardOrder(id: string, order: number) {
        cardsStore.update(id, {
            order,
        });
    }

    upsertMany(cards: Card[]) {
        cardsStore.upsertMany(cards);
    }

    updateByIds(ids: string[], data: Partial<Card>) {
        cardsStore.update(ids, data);
    }

    removeAllHighlight() {
        const updates = cardsQuery
            .getAll()
            .filter((c) => c.isHighlight)
            .map((c) => ({
                ...c,
                isHighlight: false,
            }));
        cardsStore.upsertMany(updates);
    }

    setHighlight(id: string) {
        cardsStore.update(id, {
            isHighlight: true,
        });
    }

    removeAllHints() {
        cardsStore.update(null, {
            isHint: false,
        });
    }

    updateTableauCount() {
        console.log('---update counts');
        const counts: { [id: number]: number } = {};
        cardsQuery.getAllByOwner(CardOwner.tableau).forEach((c) => {
            if (counts[c.ownerIndex]) {
                counts[c.ownerIndex] += 1;
            } else {
                counts[c.ownerIndex] = 1;
            }
        });
        cardsStore.update({
            tableauCounts: counts,
        });
    }
}

export const cardsService = new CardsService();
