<template>
    <transition name="anim-slide-bottom">
        <div v-if="show" class="sheet-wrapper">
            <div class="bg-white sheet-content w-full md:w-2/4">
                <slot></slot>
            </div>
        </div>
    </transition>
</template>

<script lang="ts">
import { ref, defineComponent, watch } from 'vue';
import { coreBus } from '@/core/core-bus';
import { subscribeTo } from '@/core/rxjs-helpers';

export default defineComponent({
    props: {
        modelValue: { type: Boolean },
    },

    setup(props, ctx) {
        const show = ref(false);

        const close = () => {
            show.value = false;
            ctx.emit('update:modelValue', false);
        };

        subscribeTo(coreBus.overlayClickedEvent$, () => {
            console.log('-----overlay clicked');
            close();
        });

        watch(
            () => props.modelValue,
            (val) => {
                show.value = val as boolean;
                coreBus.overlayToggleCmd$.next(val);
            }
        );

        return {
            show,
            close,
        };
    },
});
</script>

<style scoped>
.sheet-content {
    overflow-y: auto;
    z-index: inherit;
    background-color: #eee;
    color: #000;
    pointer-events: auto;
    position: fixed;
    align-self: flex-end;
    flex: 0 1 auto;
}
.sheet-wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    outline: none;
}
</style>
