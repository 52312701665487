<template>
    <div>
        <div class="mt-8 border border-yellow-500 rounded p-4 text-yellow-500">
            <div v-show="!showChooseNamePanel">
                Your score show under anonymous in the leader board, click choose name to change
                that
                <div class="mt-2">
                    <s-button @click="showChooseNamePanel = true" color="blue"
                        >Choose name</s-button
                    >
                </div>
            </div>
            <div v-show="showChooseNamePanel">
                <h5>Type the name bellow and click save</h5>
                <div class="my-5">
                    <input
                        v-model="name"
                        placeholder="Name..."
                        class="shadow appearance-none border rounded py-3 px-3 mb-3 text-lg text-gray-900 leading-tight bg-gray-400 focus:outline-none focus:shadow-outline"
                    />
                </div>
                <s-button @click="saveName" color="blue">Save</s-button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ref, defineComponent } from 'vue';
import { coreLocalStorage } from '@/core/core-local-storage';
import { apiClient } from '@/core/api-client';
import SButton from '@/components/SButton.vue';

export default defineComponent({
    components: { SButton },

    setup(_, ctx) {
        const name = ref('');
        const showChooseNamePanel = ref(false);

        const saveName = () => {
            if (name.value != '') {
                coreLocalStorage.updatePlayerSettings({
                    name: name.value,
                });
                apiClient.updatePlayer({
                    name: name.value,
                });
                ctx.emit('on-name-saved');
            }
        };

        return {
            showChooseNamePanel,
            saveName,
            name,
        };
    },
});

/*
@Component({
    components: { SButton }
})
export default class MissingPlayerName extends Vue {
    name = '';

    showChooseNamePanel = false;

    saveName() {
        if (this.name != '') {
            coreLocalStorage.updatePlayerSettings({
                name: this.name
            });
            apiClient.updatePlayer({
                name: this.name
            });
            this.$emit('on-name-saved');
        }
    }
}
 */
</script>

<style scoped></style>
