import { Game } from '@/core/models';
import { apiClient } from '@/core/api-client';

type Played = {
    games: { [id: string]: number[] };
};

class GameSelector {
    private winnableGames: { [id: string]: number[] } = {};

    private storeKey = 'winnable-played';

    async selectRandomGameId(game: Game) {
        const winnablesAll = this.winnableGames[game.toString()] || [];

        // filter played
        const playedIds = this.getPlayedForGame(game);
        const winnables = winnablesAll.filter((id) => playedIds.indexOf(id) < 0);

        // generate random id
        let gameId = Math.floor(Math.random() * 49999) + 1;

        // 70% of time use winnable game or random game id
        if (Math.random() < 0.7 && winnables.length > 0) {
            // eslint-disable-next-line
            gameId = winnables.pop()!;
            console.log('---user winable: ', gameId, game);
        }

        // if no more winnable left load more
        if (winnables.length == 0) {
            this.loadWinnables(game);
        }

        this.savePlayedWinnableGame(gameId, game);

        return gameId;
    }

    async loadWinnables(game: Game) {
        const winnables = await apiClient.getWinnableGames(game.toString());

        // remove played games
        const playedIds = this.getPlayedForGame(game);
        this.winnableGames[game.toString()] = winnables.filter((id) => playedIds.indexOf(id) < 0);
    }

    private getPlayedForGame(game: Game) {
        const played = this.loadPlayedWinnableGames();
        let playedIds: number[] = [];
        if (played.games[game.toString()]) {
            playedIds = played.games[game.toString()];
        }
        return playedIds;
    }

    private savePlayedWinnableGame(gameId: number, game: Game) {
        const played = this.loadPlayedWinnableGames();
        if (!played.games[game.toString()]) {
            played.games[game.toString()] = [];
        }
        if (played.games[game.toString()].length > 100) {
            played.games[game.toString()].shift();
        }
        played.games[game.toString()].push(gameId);
        localStorage.setItem(this.storeKey, JSON.stringify(played));
    }

    private loadPlayedWinnableGames(): Played {
        try {
            const str = localStorage.getItem(this.storeKey);
            if (!str) {
                return {
                    games: {},
                };
            }
            return JSON.parse(str);
        } catch {
            return {
                games: {},
            };
        }
    }
}

export const gameSelector = new GameSelector();
