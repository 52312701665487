import { Controller } from '@/core/controller';
import { appQuery } from '@/state/app.query';
import { coreBus, StartGameCmd, StatsEventType } from '@/core/core-bus';
import { gameService } from '@/state/game/game.service';
import { gameQuery } from '@/state/game/game.query';
import { coreLocalStorage } from '@/core/core-local-storage';
import { GameStatus } from '@/core/models';
import { gameSelector } from '@/core/game-selector';
import { apiClient } from '@/core/api-client';
import { gameHistory } from '@/core/game-history';
import { activityLog } from '@/core/activity-log';
import { cardsService } from '@/state/cards/cards.service';
import { donateUtil } from '@/core/donate-util';
import { cardsQuery } from '@/state/cards/cards.query';
import { moveHistory } from '@/core/move-history';
import { cardSound } from '@/core/sound';
import { coreUtil } from '@/core/core-util';

export abstract class BaseGameController extends Controller {
    private timeInterval: number | null = null;

    protected abstract initGame(): void;

    protected abstract isGameCompleted(): boolean;

    constructor() {
        super();
        this.subscribeTo(coreBus.startGameCmd$, (cmd) => {
            this.handleGameStart(cmd);
        });
        this.subscribeTo(coreBus.gameMoveCompletedEvent$, () => {
            this.handleMoveCompleted();
        });
        this.subscribeTo(appQuery.isAppHidden$, (hidden) => {
            if (hidden && gameQuery.getValue().gameStatus == GameStatus.running) {
                gameService.setGameStatus(GameStatus.paused);
            }
        });
        this.subscribeTo(coreBus.resumeClickedEvent$, () => {
            gameService.setGameStatus(GameStatus.running);
        });
        this.subscribeTo(gameQuery.gameStatus$, (status) => {
            if (status == GameStatus.running) {
                this.startOrResumeTime();
            } else {
                this.pauseTime();
            }
            if (status == GameStatus.paused) {
                coreBus.showPauseOverlay$.next();
            }
        });
        this.subscribeTo(gameQuery.gameSize$, () => {
            // use the timer case we want to fire the resize after
            // all component updated the display
            setTimeout(() => {
                this.handleResize();
            });
        });
        this.subscribeTo(coreBus.shakeCardCmd$, () => {
            cardSound.playError();
        });
    }

    protected async handleGameStart(cmd: StartGameCmd) {
        // first we set game status to none and wait for it to complete
        await gameService.setGameStatus(GameStatus.none);

        // if gameId not provided select a random one
        let finalGameId = 0;
        if (!cmd.gameId) {
            const randomId = await gameSelector.selectRandomGameId(appQuery.getActiveGame());
            gameService.setGameId(randomId);
            finalGameId = randomId;
        } else {
            gameService.setGameId(cmd.gameId);
            finalGameId = cmd.gameId;
        }

        // init game
        gameService.resetGameMetrics();
        gameService.setCanAutoFinish(false);
        gameService.setLeaderBoard([]);
        gameService.setStockRecycleCounter(0);
        gameService.setHints([]);
        cardsService.removeAllHints();
        gameService.removeFoundationHighlight();
        moveHistory.reset();
        cardSound.reset();
        this.initGame();
        gameService.setGameStatus(GameStatus.readyToDeal);

        // log activity
        activityLog.sendGameActivity(cmd.game, finalGameId, 'start-game');

        // show donate dialog if needed
        if (donateUtil.shouldShowDonate()) {
            coreBus.showDonationDialogCmd$.next();
        }
    }

    protected async handleMoveCompleted() {
        // we only start the game after user made first move
        if (gameQuery.getValue().gameStatus == GameStatus.dealCompleted) {
            gameService.setGameStatus(GameStatus.running);
            coreLocalStorage.setGameInProgress(true, appQuery.getActiveGame());
            // fire stats event
            coreBus.statsEvent$.next({
                game: appQuery.getActiveGame(),
                type: StatsEventType.gameStated,
            });
            // log activity
            activityLog.sendGameActivity(
                appQuery.getActiveGame(),
                gameQuery.getValue().gameId || 0,
                'game-first-move'
            );
            // update donate record
            donateUtil.addGameCount();
        }

        // clear hints
        gameService.clearHints();
        gameService.removeFoundationHighlight();
        gameService.removeTableauHighlight();
        cardsService.update(null, {
            isHint: false,
        });

        // if game not completed return
        if (!this.isGameCompleted()) {
            return;
        }

        // handle game completed
        gameService.setGameStatus(GameStatus.gameCompleted);
        coreLocalStorage.setGameInProgress(false, appQuery.getActiveGame());
        const game = appQuery.getActiveGame();
        const gameId = gameQuery.getValue().gameId || 0;

        // calc score
        const metrics = { ...gameQuery.getValue().gameMetrics };
        const denominator = metrics.time + metrics.moves;
        metrics.score = denominator > 0 ? Math.floor(1000000 / denominator) : 0;

        // save score to server
        const leaderboard = await apiClient.addScore({
            gameId,
            gameName: game.toString(),
            ...metrics,
            undos: metrics.undoCount,
            hints: metrics.hintCount,
        });
        gameService.setLeaderBoard(leaderboard);

        // fire stats event
        coreBus.statsEvent$.next({
            game: appQuery.getActiveGame(),
            type: StatsEventType.gameWon,
            metrics,
        });

        // log activity
        activityLog.sendGameActivity(
            appQuery.getActiveGame(),
            gameQuery.getValue().gameId || 0,
            'game-completed'
        );

        // update history
        gameHistory.addItem({
            date: new Date(),
            game,
            gameId,
            moves: metrics.moves,
            score: metrics.score,
            time: metrics.time,
        });

        // play sound
        await coreUtil.delay(1000);
        cardSound.playVictory();
    }

    protected startOrResumeTime() {
        if (this.timeInterval) {
            return;
        }
        this.timeInterval = setInterval(() => {
            gameService.increaseTime();
            // log heart-bit activity
            if (gameQuery.getValue().gameMetrics.time % 60 === 0) {
                activityLog.sendGameActivity(
                    appQuery.getActiveGame(),
                    gameQuery.getValue().gameId || 0,
                    'game-heart-bit'
                );
            }
        }, 1000);
    }

    protected pauseTime() {
        if (this.timeInterval) {
            clearInterval(this.timeInterval);
            this.timeInterval = null;
        }
    }

    protected handleResize() {
        cardsQuery.getAll().forEach((c) => {
            coreBus.cardMoveCmd$.next({
                cardId: c.id,
                duration: 0,
            });
        });
    }

    destroy() {
        super.destroy();
        this.pauseTime();
    }
}
