
import { computed, defineComponent, ref } from 'vue';
import { GameStatus, Hint } from '@/core/models';
import { gameQuery } from '@/state/game/game.query';
import BottomNav from '@/components/BottomNav.vue';
import BottomNavButton from '@/components/BottomNavButton.vue';
import { subscribeTo, useObservable } from '@/core/rxjs-helpers';
import { coreBus } from '@/core/core-bus';
import { gameService } from '@/state/game/game.service';
import { tracking } from '@/core/tracking';
import { appQuery } from '@/state/app.query';
import { cardsQuery } from '@/state/cards/cards.query';
import { cardsService } from '@/state/cards/cards.service';
import { orderBy } from 'lodash';

export default defineComponent({
    components: {
        BottomNavButton,
        BottomNav,
    },

    props: {
        showHint: { type: Boolean, default: false },
    },

    setup() {
        const gameStatus = useObservable(gameQuery.gameStatus$, GameStatus.none);
        const show = computed(() => gameStatus.value == GameStatus.running);
        const canAutoFinish = useObservable(gameQuery.canAutoFinish$, false);
        const hints = ref<Hint[]>([]);
        const hintIndex = ref(0);

        const displayHint = (hint: Hint) => {
            const updates = cardsQuery.getAll().map((c) => ({
                ...c,
                isHint: c.id == hint.card1Id || (!!hint.card2Id && c.id == hint.card2Id),
            }));
            cardsService.upsertMany(updates);
            if (hint.foundationIndex) {
                gameService.setFoundationHighlight(hint.foundationIndex);
            } else {
                gameService.removeFoundationHighlight();
            }
            if (hint.tableauIndex) {
                gameService.setTableauHighlight(hint.tableauIndex);
            } else {
                gameService.removeTableauHighlight();
            }
        };

        subscribeTo(gameQuery.hints$, (list) => {
            hints.value = orderBy(list, (x) => x.priority);
            hintIndex.value = 0;
            if (list.length > 0) {
                displayHint(hints.value[0]);
            }
        });

        const undo = () => {
            coreBus.undoMoveCmd$.next();
            gameService.clearHints();
            gameService.removeTableauHighlight();
            gameService.removeFoundationHighlight();
            cardsService.update(null, {
                isHint: false,
            });
            tracking.event('undo-clicked', {
                eventCategory: appQuery.getActiveGame().toString(),
            });
        };
        const pause = () => {
            gameService.setGameStatus(GameStatus.paused);
            tracking.event('pause-clicked', {
                eventCategory: appQuery.getActiveGame().toString(),
            });
        };
        const openMenu = () => {
            coreBus.openGameMenu$.next();
            tracking.event('game-menu-clicked', {
                eventCategory: appQuery.getActiveGame().toString(),
            });
        };

        const showAutoFinish = computed(
            () => canAutoFinish.value && gameStatus.value == GameStatus.running
        );

        const autoFinish = () => {
            coreBus.autoFinishCmd$.next();
            tracking.event('auto-finish-clicked', {
                eventCategory: appQuery.getActiveGame().toString(),
            });
        };

        const genHints = () => {
            gameService.increaseHintCount();
            coreBus.generateHintCmd$.next();
            tracking.event('gen-hint-clicked', {
                eventCategory: appQuery.getActiveGame().toString(),
            });
        };

        const prevHint = () => {
            if (hintIndex.value <= 0) {
                hintIndex.value = hints.value.length - 1;
            } else {
                hintIndex.value -= 1;
            }
            displayHint(hints.value[hintIndex.value]);
        };

        const nextHint = () => {
            if (hintIndex.value >= hints.value.length - 1) {
                hintIndex.value = 0;
            } else {
                hintIndex.value += 1;
            }
            displayHint(hints.value[hintIndex.value]);
        };

        const closeHints = () => {
            gameService.clearHints();
            gameService.removeTableauHighlight();
            gameService.removeFoundationHighlight();
            cardsService.update(null, {
                isHint: false,
            });
        };

        const totalHints = computed(() => {
            return hints.value.length;
        });

        return {
            show,
            undo,
            pause,
            openMenu,
            canAutoFinish,
            showAutoFinish,
            autoFinish,
            genHints,
            totalHints,
            hintIndex,
            displayHint,
            prevHint,
            nextHint,
            closeHints,
        };
    },
});
