
import { defineComponent, reactive, computed } from 'vue';
import { gameQuery } from '@/state/game/game.query';
import { Score } from '@/core/models';
import { coreLocalStorage } from '@/core/core-local-storage';
import { coreBus } from '@/core/core-bus';
import { appQuery } from '@/state/app.query';
import Leaderboard from '@/components/Leaderboard.vue';
import MissingPlayerName from '@/components/MissingPlayerName.vue';
import SButton from '@/components/SButton.vue';
import { subscribeTo } from '@/core/rxjs-helpers';

export default defineComponent({
    components: {
        SButton,
        Leaderboard,
        MissingPlayerName,
    },

    setup() {
        const state = reactive({
            leaderboard: [] as Score[],
            gameId: 0,
            isMissingName: false,
        });

        subscribeTo(gameQuery.gameLeaderboard$, (items) => {
            state.isMissingName = !coreLocalStorage.loadPlayerSettings().name;
            state.leaderboard = items;
            state.gameId = gameQuery.getValue().gameId || 0;
        });

        const startNewGame = () => {
            coreBus.startGameCmd$.next({
                gameId: null,
                game: appQuery.getActiveGame(),
            });
        };

        const replayGame = () => {
            coreBus.startGameCmd$.next({
                gameId: gameQuery.getValue().gameId,
                game: appQuery.getActiveGame(),
            });
        };

        const challengeFriend = () => {
            const id = gameQuery.getValue().gameId;
            if (id) {
                const game = appQuery.getValue().activeGame;
                coreBus.showChallengeFriendDialogCmd$.next({
                    game,
                    gameId: id,
                });
            }
        };

        const handleNameSaved = () => {
            state.isMissingName = false;
            state.leaderboard = [...state.leaderboard]; //to update the table view
        };

        const goToDailyChallenge = () => {
            const id = gameQuery.getValue().gameId;
            if (id) {
                const game = appQuery.getValue().activeGame;
                coreBus.showDailyChallengeDialogCmd$.next({
                    game,
                    gameId: id,
                });
            }
        };

        const game = computed(() => {
            return appQuery.getActiveGame();
        });

        return {
            state,
            startNewGame,
            replayGame,
            challengeFriend,
            handleNameSaved,
            goToDailyChallenge,
            game,
        };
    },
});
