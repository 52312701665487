import { GameStatus, Hint } from '@/core/models';
import { GameMetrics, Score, Size } from '@/core/models';
import { Store, StoreConfig } from '@datorama/akita';

export interface GameState {
    gameStatus: GameStatus;
    gameSize: Size;
    gameId: number | null;
    gameMetrics: GameMetrics;
    canAutoFinish: boolean;
    gameLeaderboard: Score[];
    foundationIndexHighlight: number | null;
    tableauIndexHighlight: number | null;
    stockRecycleCounter: number;
    numberOfSuits: number;
    hints: Hint[];
    foundationLeadingNumber: number;
}

const initialState = {
    gameStatus: GameStatus.none,
    gameSize: { width: 0, height: 0 },
    gameId: null,
    canAutoFinish: false,
    gameLeaderboard: [],
    foundationIndexHighlight: null,
    tableauIndexHighlight: null,
    stockRecycleCounter: 0,
    numberOfSuits: 0,
    hints: [],
    foundationLeadingNumber: 0,
    gameMetrics: {
        time: 0,
        moves: 0,
        score: 0,
        undoCount: 0,
        hintCount: 0,
    },
} as GameState;

@StoreConfig({ name: 'global-game' })
export class GameStore extends Store<GameState> {
    constructor() {
        super(initialState);
    }
}

export const gameStore = new GameStore();
