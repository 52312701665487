<template>
    <div class="wrapper text-center fixed bottom-0 w-full left-0">
        <slot></slot>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>

<style scoped>
.wrapper {
    opacity: 0.95;
    z-index: 50;
    background-color: rgba(35, 35, 35, 0.8);
}
</style>
