<template>
    <div>
        <bottom-sheet v-model="show">
            <div class="border-b border-gray-700 text-back cursor-pointer">
                <div
                    class="flex flex-row p-3 pl-6 w-full hover:bg-gray-400"
                    v-for="(item, i) in items"
                    :key="i"
                    @click="handleClick(item.id)"
                >
                    <div><icon :icon="item.icon" color="black"></icon></div>
                    <div class="ml-6">{{ item.title }}</div>
                </div>
            </div>
        </bottom-sheet>

        <modal-dialog v-model="showConfirmDialog" :size="290">
            <div class="p-5">
                <div class="text-lg">Start New Game?</div>
                <div class="my-5">
                    You have a game in progress. Are you sure you want to start a new game and
                    abandon the current game?
                </div>
                <div>
                    <s-button color="blue" @click="showConfirmDialog = false">NO</s-button>
                    <s-button color="blue" @click="startNewGame" class="ml-3">YES</s-button>
                </div>
            </div>
        </modal-dialog>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, computed } from 'vue';
import BottomSheet from '@/components/BottomSheet.vue';
import Icon from '@/components/Icon.vue';
import ModalDialog from '@/components/ModalDialog.vue';
import SButton from '@/components/SButton.vue';
import { Game } from '@/core/models';
import { coreBus } from '@/core/core-bus';
import { tracking } from '@/core/tracking';
import { appQuery } from '@/state/app.query';
import { coreUtil } from '@/core/core-util';
import { coreLocalStorage } from '@/core/core-local-storage';

export default defineComponent({
    components: { SButton, ModalDialog, BottomSheet, Icon },

    props: {
        game: { type: String, required: true },
        gameId: { type: Number, required: true },
        modelValue: { type: Boolean, required: true },
        gameInProgress: { type: Boolean },
        hideDailyChallenge: { type: Boolean },
        hideSound: { type: Boolean },
    },

    setup(props, ctx) {
        const show = ref(false);
        const showConfirmDialog = ref(false);
        let newGameId: number | null = null;
        const isFullScreen = ref(false);
        const playerSetting = coreLocalStorage.loadPlayerSettings();
        const mute = ref(playerSetting.mute);

        const startNewGame = () => {
            showConfirmDialog.value = false;
            ctx.emit('start-new-game', newGameId);
        };

        const requestNewGame = () => {
            if (props.gameInProgress) {
                showConfirmDialog.value = true;
                return;
            }
            startNewGame();
        };

        const items = computed(() => {
            // eslint-disable-next-line
            const list: any[] = [];
            list.push({ id: 'start-new', icon: 'plus', title: 'Start New Game' });
            list.push({ id: 'replay', icon: 'undo', title: 'Replay' });
            if (!props.hideDailyChallenge) {
                list.push({
                    id: 'daily-challenge',
                    icon: 'calendar',
                    title: 'Daily Challenge',
                });
            }
            list.push({ id: 'stats', icon: 'chart', title: 'Statistics' });
            list.push({ id: 'customize', icon: 'palette', title: 'Customize' });
            if (!props.hideSound) {
                if (mute.value) {
                    list.push({ id: 'unmute', icon: 'volume-mute', title: 'Unmute' });
                } else {
                    list.push({ id: 'mute', icon: 'volume-up', title: 'Mute' });
                }
            }
            if (isFullScreen.value) {
                list.push({ id: 'full-screen-exit', icon: 'compress', title: 'Exit Full Screen' });
            } else {
                list.push({ id: 'full-screen-expend', icon: 'expend', title: 'Full Screen' });
            }
            list.push({ id: 'leaderboard', icon: 'list', title: 'Leaderboard' });
            return list;
        });

        const handleClick = (id: string) => {
            if (id == 'start-new') {
                newGameId = null;
                requestNewGame();
            } else if (id == 'replay') {
                newGameId = props.gameId;
                requestNewGame();
            } else if (id == 'stats') {
                ctx.emit('stats-click');
            } else if (id == 'leaderboard') {
                coreBus.showLeaderBoardDialogCmd$.next({
                    game: props.game as Game,
                    gameId: props.gameId,
                });
            } else if (id == 'daily-challenge') {
                coreBus.showDailyChallengeDialogCmd$.next({
                    game: props.game as Game,
                    gameId: props.gameId,
                });
            } else if (id == 'full-screen-expend') {
                coreUtil.requestFullscreen();
                isFullScreen.value = true;
                tracking.event('full-screen-clicked', {
                    eventCategory: appQuery.getActiveGame().toString(),
                });
            } else if (id == 'full-screen-exit') {
                coreUtil.exitFullscreen();
                isFullScreen.value = false;
                tracking.event('full-screen-exit', {
                    eventCategory: appQuery.getActiveGame().toString(),
                });
            } else if (id == 'customize') {
                coreBus.showCustomizeDialogCmd$.next();
            } else if (id == 'mute') {
                coreLocalStorage.updatePlayerSettings({
                    mute: true,
                });
                mute.value = true;
            } else if (id == 'unmute') {
                coreLocalStorage.updatePlayerSettings({
                    mute: false,
                });
                mute.value = false;
            }
            show.value = false;
        };

        watch(
            () => props.modelValue,
            (val) => {
                show.value = val;
            }
        );

        watch(show, (val) => {
            ctx.emit('update:modelValue', val);
        });

        return {
            items,
            show,
            showConfirmDialog,
            handleClick,
            startNewGame,
        };
    },
});
</script>

<style scoped></style>
