
import { defineComponent } from 'vue';
import Icon from '@/components/Icon.vue';

export default defineComponent({
    components: {
        Icon,
    },

    props: {
        icon: { type: String },
        label: { type: String },
    },
});
