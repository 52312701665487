import { GameStatus, Hint, Score } from '@/core/models';
import { gameStore } from '@/state/game/game.store';

export class GameService {
    setGameStatus(gameStatus: GameStatus): Promise<void> {
        // we use timeout cause we want all subscribers to finish process one status change before
        // handling another status change.
        // in case we have one subscriber change status in the middle of handling past change
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                gameStore.update({
                    gameStatus,
                });
                resolve();
            });
        });
    }

    setGameSize(width: number, height: number) {
        gameStore.update({
            gameSize: {
                width,
                height,
            },
        });
    }

    setGameId(gameId: number | null) {
        const status = gameStore.getValue().gameStatus;
        if (!(status == GameStatus.none || status == GameStatus.gameCompleted)) {
            throw new Error(`cant set game id when status is ${status}`);
        }
        gameStore.update({
            gameId,
        });
    }

    increaseMoveCounter() {
        gameStore.update((s) => ({
            gameMetrics: {
                ...s.gameMetrics,
                moves: s.gameMetrics.moves + 1,
            },
        }));
    }

    increaseTime() {
        gameStore.update((s) => ({
            gameMetrics: {
                ...s.gameMetrics,
                time: s.gameMetrics.time + 1,
            },
        }));
    }

    addScore(score: number) {
        const newScore = gameStore.getValue().gameMetrics.score + score;
        const delta = newScore < 0 ? -gameStore.getValue().gameMetrics.score : score;
        gameStore.update((s) => ({
            gameMetrics: {
                ...s.gameMetrics,
                score: gameStore.getValue().gameMetrics.score + delta,
            },
        }));
        return delta;
    }

    increaseUndoCount() {
        gameStore.update((s) => ({
            gameMetrics: {
                ...s.gameMetrics,
                undoCount: s.gameMetrics.undoCount + 1,
            },
        }));
    }

    increaseHintCount() {
        gameStore.update((s) => ({
            gameMetrics: {
                ...s.gameMetrics,
                hintCount: s.gameMetrics.hintCount + 1,
            },
        }));
    }

    resetGameMetrics() {
        gameStore.update({
            gameMetrics: {
                moves: 0,
                score: 0,
                time: 0,
                undoCount: 0,
                hintCount: 0,
            },
        });
    }

    setLeaderBoard(data: Score[]) {
        gameStore.update({
            gameLeaderboard: data,
        });
    }

    setCanAutoFinish(can: boolean) {
        gameStore.update({
            canAutoFinish: can,
        });
    }

    setFoundationHighlight(index: number) {
        gameStore.update({
            foundationIndexHighlight: index,
        });
    }

    removeFoundationHighlight() {
        gameStore.update({
            foundationIndexHighlight: null,
        });
    }

    setTableauHighlight(index: number) {
        gameStore.update({
            tableauIndexHighlight: index,
        });
    }

    removeTableauHighlight() {
        gameStore.update({
            tableauIndexHighlight: null,
        });
    }

    setStockRecycleCounter(n: number) {
        gameStore.update({
            stockRecycleCounter: n,
        });
    }

    increaseStockRecycleCounter() {
        gameStore.update((s) => ({
            stockRecycleCounter: s.stockRecycleCounter + 1,
        }));
    }

    reduceStockRecycleCounter() {
        gameStore.update((s) => ({
            stockRecycleCounter: s.stockRecycleCounter - 1,
        }));
    }

    setNumberOfSuits(n: number) {
        gameStore.update({
            numberOfSuits: n,
        });
    }

    setHints(hints: Hint[]) {
        gameStore.update({
            hints,
        });
    }

    clearHints() {
        gameStore.update({
            hints: [],
        });
    }

    setFoundationLeadingNumber(n: number) {
        gameStore.update({
            foundationLeadingNumber: n,
        });
    }
}

export const gameService = new GameService();
