type Record = {
    gamePlayed: number;
    lastShow: number;
};

export class DonateUtil {
    private gamePlayedThreshold = 30;
    private millisThreshold = 1000 * 60 * 60 * 72; // 72 hours
    private record: Record | null = null;
    private key = 'donate';

    addGameCount() {
        this.loadRecord();
        if (!this.record) {
            return;
        }
        this.record.gamePlayed += 1;
        this.saveRecord();
    }

    shouldShowDonate() {
        this.loadRecord();
        if (!this.record) {
            return false;
        }
        if (this.record.gamePlayed <= this.gamePlayedThreshold) {
            return false;
        }
        if (new Date().getTime() - this.record.lastShow < this.millisThreshold) {
            return false;
        }

        this.record.gamePlayed = 0;
        this.record.lastShow = new Date().getTime();
        this.saveRecord();
        return true;
    }

    private loadRecord() {
        if (this.record) {
            return;
        }
        const str = localStorage.getItem(this.key);
        if (!str) {
            this.setDefaultRecord();
            return;
        }
        try {
            this.record = JSON.parse(str);
        } catch (e) {
            console.log(e);
            this.setDefaultRecord();
        }
    }

    private setDefaultRecord() {
        this.record = {
            gamePlayed: 0,
            lastShow: new Date().getTime(),
        };
    }

    private saveRecord() {
        if (!this.record) {
            return;
        }
        try {
            const str = JSON.stringify(this.record);
            localStorage.setItem(this.key, str);
        } catch (e) {
            console.log(e);
        }
    }
}

export const donateUtil = new DonateUtil();
