import { QueryEntity } from '@datorama/akita';
import { CardsState, cardsStore } from '@/state/cards/cards.store';
import { maxBy, values, orderBy, take } from 'lodash';
import { Card, CardOwner } from '@/core/models';

export class CardsQuery extends QueryEntity<CardsState> {
    cards$ = this.selectAll();

    tableauCounts$ = this.select((s) => s.tableauCounts);

    getTopByOwner(owner: CardOwner) {
        const cards = this.getAll().filter((c) => c.owner == owner);
        return maxBy(cards, (c) => c.order);
    }

    getTopByOwnerAndIndex(owner: CardOwner, ownerIndex: number) {
        const cards = this.getAll().filter((c) => c.owner == owner && c.ownerIndex == ownerIndex);
        return maxBy(cards, (c) => c.order);
    }

    getTopTableauCards(): Card[] {
        const map: { [id: number]: Card } = {};
        this.getAll()
            .filter((c) => c.owner == CardOwner.tableau)
            .forEach((c) => {
                if (map[c.ownerIndex]) {
                    if (map[c.ownerIndex].order < c.order) {
                        map[c.ownerIndex] = c;
                    }
                } else {
                    map[c.ownerIndex] = c;
                }
            });
        return values(map);
    }

    getTopCardsByOwner(owner: CardOwner): Card[] {
        const map: { [id: number]: Card } = {};
        this.getAll()
            .filter((c) => c.owner == owner)
            .forEach((c) => {
                if (map[c.ownerIndex]) {
                    if (map[c.ownerIndex].order < c.order) {
                        map[c.ownerIndex] = c;
                    }
                } else {
                    map[c.ownerIndex] = c;
                }
            });
        return values(map);
    }

    getCardByOwnerAndIndexAndOrder(owner: CardOwner, ownerIndex: number, order: number) {
        return this.getAll().find(
            (c) => c.owner == owner && c.ownerIndex == ownerIndex && c.order == order
        );
    }

    getAllByOwner(owner: CardOwner) {
        return this.getAll().filter((c) => c.owner == owner);
    }

    getAllByOwnerAndIndex(owner: CardOwner, ownerIndex: number) {
        return this.getAll().filter((c) => c.owner == owner && c.ownerIndex == ownerIndex);
    }

    getByOwnerAndIndexGreaterThenOrder(owner: CardOwner, ownerIndex: number, order: number) {
        const cards = this.getAll().filter(
            (c) => c.owner == owner && c.ownerIndex == ownerIndex && c.order > order
        );
        return orderBy(cards, (c) => c.order);
    }

    getTopByOwnerLimit(owner: CardOwner, limit: number) {
        const cards = this.getAll().filter((c) => c.owner == owner);
        const ordered = orderBy(cards, (c) => c.order, 'desc');
        return take(ordered, limit);
    }

    getOrderedByOwnerAndIndex(owner: CardOwner, ownerIndex: number) {
        const cards = this.getAll().filter((c) => c.owner == owner && c.ownerIndex == ownerIndex);
        return orderBy(cards, (c) => c.order);
    }

    getOrderedByOwner(owner: CardOwner) {
        const cards = this.getAll().filter((c) => c.owner == owner);
        return orderBy(cards, (c) => c.order);
    }

    getTableauCounts() {
        return this.getValue().tableauCounts;
    }
}

export const cardsQuery = new CardsQuery(cardsStore);
